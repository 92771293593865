import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAcl0iawl-cwkcXCSTusCJz7d_3gXeKhFo',
  authDomain: 'codex-ipactoevangelico.firebaseapp.com',
  projectId: 'codex-ipactoevangelico',
  storageBucket: 'codex-ipactoevangelico.appspot.com',
  messagingSenderId: '620978179895',
  appId: '1:620978179895:web:17d104852582076a4ad4b2',
  measurementId: 'G-RG0PNDQ3ED',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
